
import { createActions, createReducer } from "reduxsauce";

const { Types, Creators } = createActions({
  requestServices: [],
  successfulServices: ["payload"],
  errorServices: ["error"],
});

export const ServicesActionCreators = Creators;

const initialState = {
  loading: false,
  services: [],
  error: "",
  selectedService: {},
  searchText: "",
  message: "",
  categories: [],
};

const requestServices = (state = initialState) => ({
  ...state,
  loading: true,
  error: "",
  services: [],
});


const successfulServices = (state = initialState, action) => {
  const { message, services } = action.payload.result;
  return {
    ...state,
    loading: false,
    message,
    services
  };
};

const errorServices = (state = initialState, action) => ({
  ...state,
  loading: false,
  error: action.error,
  services: null,
});


export default createReducer(initialState, {
  [Types.REQUEST_SERVICES]: requestServices,
  [Types.SUCCESSFUL_SERVICES]: successfulServices,
  [Types.ERROR_SERVICES]: errorServices,
});